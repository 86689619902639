import React from 'react';
import ColumnSearch from '../ColumnSearch';
import {PopoverIcon} from "@assets/icons";
import Tippy from "@tippyjs/react";

interface ITableResizeColumProps {
 col: any;
 i: number;
 innerRef?: any;
 activeIndex?: number | null;
 mouseDown?: (index: number) => void;
 activePlatforms?: any;
 handleTogglePlatformsModalShow?: any;
 handleGetColumnSearchDebounced?: any;
 columnPopoverRef?:any
}

const TableResizeColumn = (props: ITableResizeColumProps) => {
 const {
  col,
  innerRef,
  activeIndex,
  i,
  mouseDown,
  activePlatforms,
  handleTogglePlatformsModalShow,
  handleGetColumnSearchDebounced,
  columnPopoverRef
 } = props;

  return (
     <>
      <th
          ref={innerRef}
          style={{
           position: 'sticky',
           top: '0',
           maxWidth: col.value === 'title' ? 'none' : '500px !important',
           display: 'flex',
           alignItems: 'baseline',
           justifyContent: 'flex-start',
           flexDirection: 'column',
           gap: '5px',
          }}
          className="sticky-element sticky"
      >
       <div style={col.value === 'profit' ? {cursor: 'pointer', display:'flex', alignItems: 'center', gap: '5px'} : {cursor: 'pointer'}}>
           {col.heading}
           {
               col.value === 'profit' &&
               <Tippy
                   zIndex={9991}
                   trigger="click"
                   arrow={false}
                   appendTo={columnPopoverRef.current || document.body}
                   placement="bottom"
                   content={
                       <div>
                           <p>Profit Calculation: Total Sold Price - COGS - Sales Tax - Shipping Cost Seller - Fees = PROFIT</p>
                       </div>
                   }
               >
                   <PopoverIcon
                       className={'popoverIcon'}
                       color={'#fff'}
                   ></PopoverIcon>
               </Tippy>
           }
       </div>
       <div
           style={{
            height: '100%',
            position: 'absolute',
            zIndex: 534534,
           }}
           onMouseDown={() => mouseDown && mouseDown(i)}
           className={`resize-handle ${activeIndex === i ? 'active' : 'idle'}`}
       />
       {col.value !== 'image' &&
           col.value !== 'title' &&
           col.value !== 'total_sold_price' &&
           col.value !== 'Shop' &&
           col.value !== 'actions' &&
           col.value !== 'shop_id' &&
           col.value !== 'date_sold' &&
           col.value !== 'sku' &&
           col.value !== 'shipping_cost_buyer' &&
           col.value !== 'shipping_cost_seller' &&
           col.value !== 'cogs' &&
           col.value !== 'linked' &&
           col.value !== 'quantity' && (
               <ColumnSearch
                   activePlatforms={activePlatforms}
                   name={col.value}
                   handleTogglePlatformsModalShow={handleTogglePlatformsModalShow}
                   handleGetColumnSearchDebounced={handleGetColumnSearchDebounced}
               />
           )}
      </th>
     </>
 )


};

export default TableResizeColumn;
